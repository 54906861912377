<template>
	<div class="panchanggraph">
		<br/>
		<div class="chartcontainer">
			<div class="gridheading"><b>Panchang Timeline</b></div>
			<div id="example5.2" style="height:720px;" class="centered full-width">Loading...</div>
		</div>
		<br/>
		<div class="grid">
			<div class="griditem">
				<div class="gridheading"><b>Paksha</b></div>
				<div class="grid">
					<div v-for="paksha in pakshas" :key="paksha[1]" class="griditem">
						<div><b class="griditemheading">{{paksha[1]}}:</b></div>
						<span><b>Start: </b>{{paksha[3]}}</span><br/>
						<span><b>End: </b>{{paksha[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>	
			</div>	
			<div class="griditem">
				<div class="gridheading"><b>Vaara</b></div>
				<div class="grid">
					<div v-for="vaara in vaaras" :key="vaara[1]" class="griditem">
						<div><b class="griditemheading">{{vaara[1]}}:</b></div>
						<span><b>Start: </b>{{vaara[3]}}</span><br/>
						<span><b>End: </b>{{vaara[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>		
			<div class="griditem">
				<div class="gridheading"><b>Tithi</b></div>
				<div class="grid">
					<div v-for="tithi in tithis" :key="tithi[1]" class="griditem">
						<div><b class="griditemheading">{{tithi[1]}}:</b></div>
						<span><b>Start: </b>{{tithi[3]}}</span><br/>
						<span><b>End: </b>{{tithi[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>		
			<div class="griditem">
				<div class="gridheading"><b>Nakshatra</b></div>
				<div class="grid">
					<div v-for="nakshatra in nakshatras" :key="nakshatra[1]" class="griditem">
						<div><b class="griditemheading">{{nakshatra[1]}}:</b></div>
						<span><b>Start: </b>{{nakshatra[3]}}</span><br/>
						<span><b>End: </b>{{nakshatra[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>		
			<div class="griditem">
				<div class="gridheading"><b>Yoga</b></div>
				<div class="grid">
					<div v-for="yoga in yogas" :key="yoga[1]" class="griditem">
						<div><b class="griditemheading">{{yoga[1]}}:</b></div>
						<span><b>Start: </b>{{yoga[3]}}</span><br/>
						<span><b>End: </b>{{yoga[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="griditem">
				<div class="gridheading"><b>Karana</b></div>
				<div class="grid">
					<div v-for="karana in karanas" :key="karana[1]" class="griditem">
						<div><b class="griditemheading">{{karana[1]}}:</b></div>
						<span><b>Start: </b>{{karana[3]}}</span><br/>
						<span><b>End: </b>{{karana[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div v-if="amritaSiddhiYogas" class="griditem">
				<div class="gridheading"><b>Amrita Siddhi Yoga</b></div>
				<div class="grid">
					<div v-for="amritaSiddhiYoga in amritaSiddhiYogas" :key="amritaSiddhiYoga[1]" class="griditem">
						<div><b class="griditemheading">{{amritaSiddhiYoga[1]}}:</b></div>
						<span><b>Start: </b>{{amritaSiddhiYoga[3]}}</span><br/>
						<span><b>End: </b>{{amritaSiddhiYoga[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div v-if="sarvathaSiddhiYogas" class="griditem">
				<div class="gridheading"><b>Amrita Siddhi Yoga</b></div>
				<div class="grid">
					<div v-for="sarvathaSiddhiYoga in sarvathaSiddhiYogas" :key="sarvathaSiddhiYoga[1]" class="griditem">
						<div><b class="griditemheading">{{sarvathaSiddhiYoga[1]}}:</b></div>
						<span><b>Start: </b>{{sarvathaSiddhiYoga[3]}}</span><br/>
						<span><b>End: </b>{{sarvathaSiddhiYoga[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="griditem">
				<div class="gridheading"><b>Bela</b></div>
				<div class="grid">
					<div v-for="bela in belas" :key="bela[1]" class="griditem">
						<div><b class="griditemheading">{{bela[1]}}:</b></div>
						<span><b>Start: </b>{{bela[3]}}</span><br/>
						<span><b>End: </b>{{bela[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="griditem">
				<div class="gridheading"><b>Abhijit</b></div>
				<div class="grid">
					<div v-for="abhijit in abhijits" :key="abhijit[1]" class="griditem">
						<div><b class="griditemheading">{{abhijit[1]}}:</b></div>
						<span><b>Start: </b>{{abhijit[3]}}</span><br/>
						<span><b>End: </b>{{abhijit[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="griditem">
				<div class="gridheading"><b>Rahukaal</b></div>
				<div class="grid">
					<div v-for="rahukal in rahukals" :key="rahukal[1]" class="griditem">
						<div><b class="griditemheading">{{rahukal[1]}}:</b></div>
						<span><b>Start: </b>{{rahukal[3]}}</span><br/>
						<span><b>End: </b>{{rahukal[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="griditem">
				<div class="gridheading"><b>Yamagandam</b></div>
				<div class="grid">
					<div v-for="yamaganda in yamagandam" :key="yamaganda[1]" class="griditem">
						<div><b class="griditemheading">{{yamaganda[1]}}:</b></div>
						<span><b>Start: </b>{{yamaganda[3]}}</span><br/>
						<span><b>End: </b>{{yamaganda[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>		
			<div class="griditem">
				<div class="gridheading"><b>Gulika</b></div>
				<div class="grid">
					<div v-for="gulik in gulika" :key="gulik[1]" class="griditem">
						<div><b class="griditemheading">{{gulik[1]}}:</b></div>
						<span><b>Start: </b>{{gulik[3]}}</span><br/>
						<span><b>End: </b>{{gulik[4]}}</span>
						<div>&nbsp;</div>
					</div>
				</div>
			</div>				
		</div>
		<div>
			<div class="grid">
				<div class="griditem">
					<div class="gridheading"><b>Horas</b></div>
					<div class="grid">
						<div v-for="hora in horas" :key="hora[1]" class="griditem">
							<div ><b class="griditemheading">{{hora[1]}}:</b></div>
							<span><b>Start: </b>{{hora[3]}}</span><br/>
							<span><b>End: </b>{{hora[4]}}</span>
							<div>&nbsp;</div>
						</div>
					</div>
				</div>
			</div>
		</div>	
		<br/>
		<div>
			<div class="grid">
				<div class="griditem">
					<div class="gridheading"><b>Chogadiyas</b></div>
					<div class="grid">
						<div v-for="chogadiya in chogadiyas" :key="chogadiya[1]" class="griditem">
							<div ><b class="griditemheading">{{chogadiya[1]}}:</b></div>
							<span><b>Start: </b>{{chogadiya[3]}}</span><br/>
							<span><b>End: </b>{{chogadiya[4]}}</span>
							<div>&nbsp;</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid">
				<div class="griditem">
					<div class="gridheading"><b>AmritaMahendra</b></div>
					<div class="grid">
						<div v-for="amritaMahendra in amritaMahendras" :key="amritaMahendra[1]" class="griditem">
							<div><b class="griditemheading">{{amritaMahendra[1]}}:</b></div>
							<span><b>Start: </b>{{amritaMahendra[3]}}</span><br/>
							<span><b>End: </b>{{amritaMahendra[4]}}</span>
							<div>&nbsp;</div>
						</div>
					</div>
				</div>
			</div>	
		</div>	
	</div>
</template>

<script>
	import { checkServerIdentity } from 'tls';
	import moment from 'moment-timezone';
	moment.tz.setDefault('UTC');
	import Connector from '@/connector'

    export default {
		props:['panchangDate', 'city'],
        data() {
            return {
				panchang: null,
				google : window.googleChart,
				pakshas: null,
				tithis: null,
				vaaras: null,
				nakshatras: null,
				yogas: null,
				karanas: null,
				horas: null,
				chogadiyas: null,
				abhijits: null,
				rahukals: null,
				amritaSiddhiYogas: null,
				sarvathaSiddhiYogas: null,
				yamagandam: null,
				gulika: null,
				belas: null,
				amritaMahendras: null
            };
		},
		methods: {
			getDate(date){
				const momentDateUtc = moment.utc(date, 'Do MMM YY, H:mm:ss');
				const localDate = momentDateUtc.clone().tz('Asia/Kolkata');
				return localDate.format('Do MMM YY, H:mm:ss');
			},
			convertDates(rows){
				const newRows = [];
                let newRow = [];
				for (let i = 0; i < rows.length; i++){
					const row = rows[i];
					newRow = [];
					newRow.push(row[0]);
					newRow.push(row[1]);
					newRow.push(row[2]);
					newRow.push(this.getDate(row[3]));
					newRow.push(this.getDate(row[4]));
					newRows.push(newRow);
				}
				return newRows;
			},
			handleResize() {
				this.showPanchang();
			},
			showPanchang(){
				this.google.charts.load("current", {packages:["timeline"]});
				this.google.charts.setOnLoadCallback(this.drawChart);
			},
			drawChart() {
				const container = document.getElementById('example5.2');
				container.innerHTML = '';
				const chart = new this.google.visualization.Timeline(container);
				const dataTable = new this.google.visualization.DataTable();

				dataTable.addColumn({ type: 'string', id: 'Type' });
				dataTable.addColumn({ type: 'string', id: 'Name' });
				dataTable.addColumn({ type: 'string', id: 'style', role: 'style' });
				dataTable.addColumn({ type: 'date', id: 'Start' });
				dataTable.addColumn({ type: 'date', id: 'End' });
				
				let rows = [];

				const sunSigns = this.panchang.sunSigns;
				rows = this.getRows(sunSigns);
				dataTable.addRows(rows);
				this.sunSigns = this.convertDates(rows);

				const paksha = this.panchang.paksha;
				rows = this.getRows(paksha);
				this.pakshas = this.convertDates(rows); 
				dataTable.addRows(rows);

				const moonSigns = this.panchang.moonSigns;
				rows = this.getRows(moonSigns);
				dataTable.addRows(rows);
				this.moonSigns = this.convertDates(rows);

				const vaar = this.panchang.vaar;
				rows = this.getRows(vaar);
				dataTable.addRows(rows);
				this.vaaras = this.convertDates(rows);

				const nakshatra = this.panchang.nakshatra;
				rows = this.getRows(nakshatra);
				dataTable.addRows(rows);
				this.nakshatras = this.convertDates(rows);

				const tithi = this.panchang.tithi;
				rows = this.getRows(tithi);
				this.tithis = this.convertDates(rows);
				dataTable.addRows(rows);

				const yoga = this.panchang.yoga;
				rows = this.getRows(yoga);
				dataTable.addRows(rows);
				this.yogas = this.convertDates(rows);

				const karana = this.panchang.karana;
				rows = this.getRows(karana);
				dataTable.addRows(rows);
				this.karanas = this.convertDates(rows);

				const chogadiyas = this.panchang.chogadiyas;
				rows = this.getRows(chogadiyas);
				dataTable.addRows(rows);
				this.chogadiyas = this.convertDates(rows);

                const belas = this.panchang.belas;
				rows = this.getRows(belas);
				dataTable.addRows(rows);
				this.belas = this.convertDates(rows);
				
				const horas = this.panchang.horas;
				rows = this.getRows(horas);
				dataTable.addRows(rows);
				this.horas = this.convertDates(rows);
				
				const amritaMahendras = this.panchang.amritaMahendras;
				rows = this.getRows(amritaMahendras);
				dataTable.addRows(rows);
				this.amritaMahendras = this.convertDates(rows);
				
				const abhijit = this.panchang.abhijit;
				rows = this.getRows(abhijit);
				dataTable.addRows(rows);
				this.abhijits = this.convertDates(rows);

				if (this.panchang.amritaSiddhiYoga){
					const amritaSiddhiYoga = this.panchang.amritaSiddhiYoga;
					rows = this.getRows(amritaSiddhiYoga);
					dataTable.addRows(rows);
					this.amritaSiddhiYogas = this.convertDates(rows);
				}

				if (this.panchang.sarvathaSiddhiYogas){
					const sarvathaSiddhiYogas = this.panchang.sarvathaSiddhiYogas;
					rows = this.getRows(sarvathaSiddhiYogas);
					dataTable.addRows(rows);
					this.sarvathaSiddhiYogas = this.convertDates(rows);
				}
				
				const rahuKaal = this.panchang.rahuKaal;
				rows = this.getRows(rahuKaal);
				dataTable.addRows(rows);
				this.rahukals = this.convertDates(rows);

				const yamagandam = this.panchang.yamagandam;
				rows = this.getRows(yamagandam);
				dataTable.addRows(rows);
				this.yamagandam = this.convertDates(rows);

				const gulika = this.panchang.gulika;
				rows = this.getRows(gulika);
				dataTable.addRows(rows);
				this.gulika = this.convertDates(rows);

				const options = {
					timeline: { colorByRowLabel: true,
								rowLabelStyle: {/*fontName: 'Helvetica', fontSize: 24,*/ color: 'darkgrey' },
								barLabelStyle: {/* fontName: 'Garamond', fontSize: 14*/ color: 'red' } 
					},
					avoidOverlappingGridLines: true,
					backgroundColor: '#1c2940',
					title: "Panchang Timeline",
					legend: {
						textStyle:{color: 'white'}
					}
				};

				chart.draw(dataTable, options);
				window.addEventListener('resize', this.drawChart, false);
			},
			getRows(periods){
				if (periods == null)
					return;
				const rows =[];
				let row = [];
				let type;
				let name;
				let color;
				let start;
				let end;
				if (Array.isArray(periods)){
					for (let i = 0; i < periods.length; i++){
						if (periods[i] == null)
							continue;
						row = [];
						type = periods[i].type;
						name = periods[i].name;
						color = periods[i].color;
						start = new Date(periods[i].start); 
						end = new Date(periods[i].end);
						row.push(type);
						row.push(name);
						row.push(color);
						row.push(start);
						row.push(end);
						
						rows.push(row);
					}
				}else{
					type = periods.type;
					name = periods.name;
					color = periods.color;
					start = new Date(periods.start);
					end = new Date(periods.end);
					row.push(type);
					row.push(name);
					row.push(color);
					row.push(start);
					row.push(end);
					
					rows.push(row);
				}
				return rows;
			},
			responseHandler(response){
				this.panchang = response.data;
			},
			errorHandler(){
				const container = document.getElementById('example5.2');
				container.innerHTML = 'Error while processing request';
			}
		},
		async mounted(){
			const panchangDateString = this.panchangDate.format('DD-MM-YYYY')
			const data = {
				panchangDateString: panchangDateString,
				city: this.city
			};
			const connector = new Connector();
			await connector.send(data, this.responseHandler, this.errorHandler);
			// await this.axios.get(process.env.VUE_APP_SERVER_URL + "/astro/panchang?dateString="+panchangDateString+"&place="+this.city)
			// .then(response => {
			// 	this.panchang = response.data;
			// }).catch(error => {
			// 	const container = document.getElementById('example5.2');
			// 	container.innerHTML = 'Error while processing request';
			// });
			if (this.panchang.tithi)
				this.showPanchang();
			else{
				const container = document.getElementById('example5.2');
				container.innerHTML = 'No data available for display';
			}
		}
    }
</script>
<style scoped>
.panchanggraph{
	color: darkgrey;
}
.grid {
	/* Define the element as a grid container */
	display: grid;
	/* Auto-fit as many items on a row as possible without going under 180px */
	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
	/* A little spacing between articles */
	grid-gap: 1em;
	/* margin-bottom: .5em;
	margin-left: .5em;
	margin-right: .5em; */
	/* background: #2B2B2B; */
	background: #111826;
	/* background-image: var(--bg-gradient-two); */
	border-radius: 5px;
	/* padding-top: 8px;
	padding-bottom: 5px;
	padding-left: 5px;
	padding-right: 5px; */
	/* color: white; */
}

.griditem{
	white-space: initial;
	border-radius: 5px;
	background: #1c2940;
	margin-top: 2px;
}
.griditem span{
	text-align: left;
	white-space: initial;
	color:darkgrey;
	font-family: arial;
	font-size: 12px;
}
.gridheading{
	background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(
90deg,#3B4755 0,#3B4755 74px, #3B4755 0, #3B4755);
	margin: 0;
	padding: 2px;
	border-radius: 3px;
}
.griditemheading{
	background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(
90deg,#3B4755 0,#3B4755 74px, #3B4755 0, #3B4755);
	padding: 2px;
	margin: 3px;
	border-radius: 3px;
}

.chartcontainer{
	background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(
90deg,#3B4755 0,#3B4755 74px, #3B4755 0, #3B4755);
}
/* Single column display for phones */
@media (max-width: 459px) {
	.grid {
		display: flex;
		flex-direction: column;
	}
} 

</style>