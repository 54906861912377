import Response from './response';
import axios from 'axios'

export default class Connector{    
    static sessionId = 0;
    static onMessageMap = new Map();
    static counter = 0;
    // constructor(appPath){
    //     if (Connector.websocket === null){            
    //         const wsURL = "ws://192.168.1.5:8090/tkjena/ws";
    //         Connector.websocket = new WebSocket(wsURL);
    //         Connector.websocket.onmessage = this.onmessage;
    //     }
    // }
        
    resetSession(){
        this.userId = 0;
        this.sessionId = 0;  
        if (Connector.websocket != null){ 
            Connector.websocket.close();
        } 
        Connector.websocket = null;         
    }
            
    async send(data, responseHandler, errorHandler){
        console.log("inside");
        data.sessionId = Connector.sessionId;
        data.actionId = 1;
        const axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
            }
        };
        await axios.post(process.env.VUE_APP_SERVER_URL + "/astro/panchang", data, axiosConfig)
            .then(responseHandler)
            .catch(errorHandler);
                
        // jsonObject["sessionId"] = Connector.sessionId;   
        // const counterValue = this.getCounterValue();
        // const tag = {
        //     id: counterValue
        // }
        // jsonObject["tag"] = tag;
        // Connector.onMessageMap.set(counterValue, responseHandler);
        // if (arguments.length === 3){
        //     Connector.websocket.onclose = onCloseHandler;     
        // }
       
        // Connector.websocket.send(JSON.stringify(jsonObject));
    }

    // test(){
    // }
        
    onmessage(event) {
        let response;
        let tagId;
        try{        
            response = new Response(event.data); 
            tagId = response.getTagValue("id"); 
            if (!response.isSuccess()){
                //alert(response.getMessage());
            }else{
                const actionId = response.getActionId();            
                if (actionId === 1){ //SIGNIN                    
                    Connector.sessionId = response.getResponseDataValue('sessionId');
                    Connector.userId = response.getResponseDataValue('userId');
                }else if (actionId === 2){ //SIGNOUT
                    //resetSession();
                    return;
                }
            }            
            
            const responseHandler = Connector.onMessageMap.get(tagId);            
            
            responseHandler(response);

            Connector.onMessageMap.delete(tagId);
        }catch(error){
            Connector.onMessageMap.delete(tagId);
            alert("Error: " + error.message);
            alert(error.stack);            
            const jsonObject = { 
                "step" : "onResponse",
                "errorMessage" : error.stack,                                
                "actionId" : -1,
                "errorRequestId" : response.getResponseInfoValue('requestId')
            };
        }
    }
    
    // onclose(){        
    // }        
    
    getCounterValue(){
        Connector.counter = Connector.counter + 1;
        return Connector.counter;
    }
}
